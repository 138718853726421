<template>
	<div class="in-ready">
		<p class="in-ready-text"><span class="color-orange">준비중</span>입니다.</p>
	</div>
</template>

<script>
export default {
	name: 'InReady',
	props: {},
};
</script>
