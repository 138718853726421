<template>
	<main id="container" class="page-container bg-wave">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="동행뉴스" />
				</div>
			</div>
		</div>
		<div class="page-content news-list">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제 소식을</span> 빠르게 확인하세요.</h3>
				</div>

				<div class="news-list-content">
					<ul v-if="newsList.length > 0" class="content-list">
						<li v-for="(item, idx) in newsList" class="content-list-li" :key="idx">
							<router-link :to="`/ko/news/${item.campnNewsId}`" class="content-list-item">
								<div class="content-list-desc">
									<div class="content-list-desc-img">
										<img :src="item.thmbnlImgPtUrl" :alt="item.campnNewsTit" />
									</div>
									<div class="content-list-desc-text">
										<span class="content-list-desc-title">{{ item.campnNewsTit }}</span>
<!--										<span class="content-list-desc-date">{{ getTimestampToDateFormat(item.regDt) }}</span>-->
									</div>
								</div>
							</router-link>
						</li>
					</ul>
					<in-ready v-else />
				</div>
			</div>
		</div>
		<div class="pagination-wrap">
			<portal-pagination v-if="isPaging" page-name="NewsList" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getNewsList="getNewsList"></portal-pagination>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_NEWS_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, timestampToDateFormat } from '@/assets/js/utils';

import PageHeader from '@/components/content/PageHeader';
import PortalPagination from '@/components/PortalPaginationRoute';
import InReady from '@/components/common/InReady';
export default {
	name: 'NewsList',
	components: { PageHeader, PortalPagination, InReady },
	data: () => ({
		pagingMethodName: 'getNewsList',
		pageNo: 1,
		pageSize: 12,
		pageListSize: 10,
		totalCount: 0,
		isPaging: false,

		newsList: [],
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		campnId() {
			return this.campn.campnId;
		},
		queries() {
			const result = {};
			// result.searchName = this.searchName;
			// result.ctgrDcd = this.ctgrDcd;
			return result;
		},
	},
	watch: {},
	created() {
		this.getNewsList(this.pageNo);
	},
	mounted() {},
	methods: {
		getNewsList(pageNo) {
			this.pageNo = pageNo;
			this.newsList = [];

			// api 호출
			this.$store
				.dispatch(`news/${ACT_GET_NEWS_LIST}`, {
					campnId: this.campnId,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.newsList = getCheckItems(res);
						this.isPaging = true;
						setPaging(this, res);
					} else {
						// this.isNoResult = true;
						this.isPaging = false;
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getTimestampToDateFormat(timestamp) {
			return timestampToDateFormat(timestamp, 'yyyy-MM-dd');
		},
	},
};
</script>
